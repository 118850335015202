<template>
  <nav v-if="footerNavigation.length" class="pt-3 lg:pt-0 flex justify-center">
    <ul
      class="grid grid-flow-row lg:grid-flow-col auto-cols-auto gap-5 lg:gap-20 font-serif font-bold text-xl lg:text-2xl text-primary"
      :style="svgContent"
    >
      <li v-for="(item, index) in footerNavigation" :key="index" class="inline-flex justify-center">
        <NuxtLink :to="item.path" :target="item.type === 'EXTERNAL' ? '_blank' : ''" class="fooNav__link before:pr-4">
          {{ item.title }}
        </NuxtLink>
      </li>
    </ul>
  </nav>
</template>
<script setup lang="ts">
const footerNavigation = useFooterNavigation()
const parishConfig = useParishConfig()

const svgContent = computed(() => ({
  '--svgContent': `url("data:image/svg+xml,%3Csvg width='10' height='11' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5863 2.13672C2.5863 2.13672 16.0713 9.72005 13.7261 9.17839C11.3809 8.63672 2.46904 13.4034 2 15.1367' stroke='${encodeURIComponent(parishConfig.value.colors.primary)}' stroke-width='2.7' stroke-linecap='round'/%3E%3C/svg%3E")`,
  '--svgContentLg': `url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5863 2.13672C2.5863 2.13672 16.0713 9.72005 13.7261 9.17839C11.3809 8.63672 2.46904 13.4034 2 15.1367' stroke='${encodeURIComponent(parishConfig.value.colors.primary)}' stroke-width='2.7' stroke-linecap='round'/%3E%3C/svg%3E")`,
}))
</script>
<style scoped>
.fooNav__link::before {
  content: var(--svgContent);
}

@media screen(lg) {
  .fooNav__link::before {
    content: var(--svgContentLg);
  }
}
</style>
