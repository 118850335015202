<template>
  <article class="grid grid-cols-2 custom-grid gap-x-10 justify-between">
    <div class="flex flex-col space-y-1">
      <Heading v-if="globalSetting.supportUs?.heading" :size="3" :text="globalSetting.supportUs.heading" class="text-center lg:text-left" />
      <p v-if="globalSetting.supportUs?.description" class="text-grey-light text-sm text-center lg:text-left">
        {{ globalSetting.supportUs.description }}
      </p>
      <p class="inline-flex grow items-end text-primary text-lg sm:text-xl justify-center lg:justify-start font-semibold">
        IBAN: {{ formattedIban }}
      </p>
    </div>
    <div v-if="payQRSvg" v-html="payQRSvg" class="hidden lg:block w-36 self-center"></div>
  </article>
</template>
<script setup lang="ts">
const globalSetting = useGlobalSetting()
const payQRSvg = ref('')
const formattedIban = computed(() =>
  globalSetting.value?.supportUs?.iban?.replace(/^SK(\d{2})(\d{4})(\d{4})(\d{4})(\d{4})(\d{0,4})$/, 'SK$1 $2 $3 $4 $5 $6'),
)
const ibanSpaceLess = computed(() => globalSetting.value?.supportUs?.iban?.replace(/\s/g, ''))

if (globalSetting.value?.payQR) {
  const { data, error } = await useFetch(`/api/qrcode`, {
    query: {
      iban: ibanSpaceLess.value,
      amount: globalSetting.value?.payQR?.amount,
      payment_note: globalSetting.value?.payQR?.paymentNote,
      vs: globalSetting.value?.payQR?.vs,
      ks: globalSetting.value?.payQR?.ks,
      ss: globalSetting.value?.payQR?.ss,
      beneficiary_name: globalSetting.value?.payQR?.beneficiaryName,
      beneficiary_address1: globalSetting.value?.payQR?.beneficiaryAddress1,
    },
    immediate: true,
  })
  if (data.value) {
    payQRSvg.value = data.value
  }
  if (error.value) {
    console.error(error.value?.message)
  }
}
</script>
<style scoped>
.custom-grid {
  grid-template-columns: auto;
}

@media screen(lg) {
  .custom-grid {
    grid-template-columns: auto min-content;
  }
}
</style>
