<template>
  <section class="grid lg:grid-cols-2 xl:grid-cols-7 lg:gap-x-10 xl:gap-x-16 pt-16 lg:pt-20 horizontal-border" :style="borders">
    <AppFooterCopyright
      :class="[
        'row-start-2 lg:row-start-1 xl:col-span-4',
        { 'mt-11 lg:mt-0 pt-16 lg:pt-0 horizontal-border vertical-border': globalSetting.supportUs },
      ]"
    />
    <AppFooterSupportUs v-if="globalSetting.supportUs" class="xl:col-span-3" />
  </section>
</template>
<script setup lang="ts">
const globalSetting = useGlobalSetting()
const parishConfig = useParishConfig()

const borders = computed(() => ({
  '--horizontal-border': `url("data:image/svg+xml,%3Csvg width='1445' height='29' viewBox='0 0 1445 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1436.6 3.13672C1550.6 53.6365 -115.296 -15.8645 9.70381 26.1357' stroke='${encodeURIComponent(parishConfig.value.colors.secondary.light)}' stroke-width='5' stroke-linecap='round'/%3E%3C/svg%3E")`,
  '--vertical-border': `url("data:image/svg+xml,%3Csvg width='23' height='175' viewBox='0 0 23 175' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 7.50491C25.1143 -31.3385 -14.4857 202.566 21 168.79' stroke='${encodeURIComponent(parishConfig.value.colors.secondary.light)}' stroke-width='5'/%3E%3C/svg%3E")`,
}))
</script>
<style scoped>
.horizontal-border {
  border-image-slice: 30 0 0 0;
  border-image-width: 23px 0px 0 0px;
  border-image-outset: 0px 0px 0px 0px;
  border-image-repeat: stretch stretch;
  border-image-source: var(--horizontal-border);
  @apply border border-solid;
}

@media screen(lg) {
  .vertical-border {
    border-image-slice: 0 23 0 0;
    border-image-width: 18px 18px 18px 0px;
    border-image-outset: 30px 0px 30px 0;
    border-image-repeat: stretch stretch;
    border-image-source: var(--vertical-border);
    @apply border border-solid;
  }
}
</style>
