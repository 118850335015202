<template>
  <article class="flex flex-col lg:flex-row gap-7 lg:pr-10">
    <div class="space-y-2 flex-grow">
      <p class="text-center lg:text-left text-grey font-semibold text-xl">{{ parishName }}</p>
      <small class="copyright"
        >Design by <a href="https://www.peterhodak.com/" target="_blank">Peter Hodak</a> <br />
        Code by <a href="https://www.damiandlugosch.com" target="_blank">Damian Dlugosch</a></small
      >
      <small class="copyright">Copyright &copy; {{ currentYear }}</small>
    </div>
    <AppFooterSocials v-if="globalSetting.socialNetworks" />
  </article>
</template>

<script setup lang="ts">
const globalSetting = useGlobalSetting()
const parishName = computed(() => {
  const { heading, subheading } = globalSetting.value
  const transformedHeading = heading?.charAt(0).toLowerCase() + heading?.slice(1)
  return `${subheading}, ${transformedHeading}`
})
const currentYear = new Date().getFullYear()
</script>

<style lang="scss" scoped>
.copyright {
  @apply text-center lg:text-left block text-grey-light text-sm font-normal;
}
</style>
